/* eslint-disable no-console */

const { DateTime: LuxonDateTime } = require('luxon');

export class DateTime {
  static offsetMillis = 0;

  static centralTimeOffSetFromUTCMillis = 0;

  static getCurrentDateTime() {
    return new Date(Date.now() + DateTime.offsetMillis);
  }

  static getCurrentDateTimeWithCentralOffset() {
    return new Date(
      Date.now() +
        DateTime.centralTimeOffSetFromUTCMillis +
        DateTime.offsetMillis
    );
  }

  static setTimeOffsetMillis(offsetMillis: number) {
    DateTime.offsetMillis = offsetMillis;
  }

  static setTimezoneOffset() {
    if (process.env.ENABLE_TIMEZONE_OFFSET?.toLowerCase() === 'true') {
      const currentDateInCentralTime =
        LuxonDateTime.local().setZone('America/Chicago');
      DateTime.centralTimeOffSetFromUTCMillis =
        currentDateInCentralTime.offset * 60 * 1000;

      console.log(
        `Timezone offset is enabled: ${DateTime.centralTimeOffSetFromUTCMillis}`
      );
    }
  }
}
