import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { usePrimaryCare } from '../../hooks/usePrimaryCare';
import { useProviderResults } from '../../hooks/useProviderSearch';
import { getShowEditPCP } from '../../utils/primaryCare.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { EditPCPMenu } from '../PrimaryCareProvider/EditPCPMenu/EditPCPMenu';
import { getFormattedDate } from '../Utils/datesUtils/formatDate';
import {
  LayoutContainer,
  YourPCPContainer,
  YourPCPMessageDetailsContainer,
} from './DetailsPageHeader.styled';

export type Props = {
  providerPCPId: string;
  memberPCPId: string;
  endDate: string;
  displayFutureTerminationDate?: boolean;
  locationTotalCount?: number;
  primaryCarePCPId?: string;
  normalizedStoreProviderId?: string;
};

export const ProviderDetailBanner = ({
  providerPCPId,
  memberPCPId,
  endDate,
  displayFutureTerminationDate,
  locationTotalCount,
  primaryCarePCPId,
  normalizedStoreProviderId,
}: Props) => {
  const { t } = useTranslation();
  const [providersResult] = useProviderResults({});
  const { data } = providersResult;
  const currentMember = getCurrentMember();
  const lob = currentMember?.lineOfBusiness;
  const isPreEffective = useCurrentMemberIsPreEffective();

  const { data: pcpData } = usePrimaryCare();
  const { primaryCare } = pcpData;
  const lockInIndicator = primaryCare?.[0]?.lockInIndicator;
  const showEditPCP = getShowEditPCP(currentMember.editPcp, lockInIndicator);

  const displayProviderBanner = (providerPCP, memberPCP, primaryCarePCPId) =>
    providerPCP === memberPCP || normalizedStoreProviderId === primaryCarePCPId;

  const YourPCPMessage = () => {
    if (
      !displayProviderBanner(providerPCPId, memberPCPId, primaryCarePCPId) ||
      getFormattedDate(endDate) ||
      displayFutureTerminationDate
    ) {
      return null;
    }

    return (
      <React.Fragment>
        <YourPCPContainer>
          <LayoutContainer>
            <YourPCPMessageDetailsContainer>
              <IconMaterial color="$primary1" icon="check" size="$sm" />
              <Text
                css={{
                  'abyss-text-root': {
                    fontSize: '12.64px',
                    fontWeight: '700',
                    fontStyle: 'normal',
                    lineHeight: '16px',
                    color: '$primary1 ! important',
                  },
                }}
                data-testid="pcp-banner-content"
              >
                {t('PCP_BANNER.CONTENT')}
              </Text>
            </YourPCPMessageDetailsContainer>
            {showEditPCP ? (
              <EditPCPMenu
                badge
                dependentSeqNbr={currentMember?.demographics?.dependentSeqNbr}
                isPreEffective={isPreEffective}
                lineOfBusiness={lob}
                locationForAnalytics="Edit PCP"
                providerID={providerPCPId}
                providerType={
                  data?.providers?.[0]?.providerType ||
                  data?.medicalGroup?.[0]?.providerType
                }
                showPcpLocation={locationTotalCount}
              />
            ) : null}
          </LayoutContainer>
        </YourPCPContainer>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <YourPCPMessage />
    </React.Fragment>
  );
};
