import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../../common/Utils';
import { useLagoon } from '../../../../hooks/useLagoon';
import { capitalizeEachWord } from '../../../../utils/providerNames.utils';

type Competence = {
  category?: string;
  description?: string;
};

type Props = {
  culturalCompetencies?: Competence[] | string[];
  mobileScreen: boolean;
};
export const ListCulturalCompetencies = ({
  culturalCompetencies = [],
  mobileScreen,
}: Props) => {
  const { t } = useTranslation();
  const featureFlags = useLagoon('feature-flags')();
  const isEnableL2CulturalCompetencies = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_L2_CULTURAL_COMPETENCIES
  );
  return (
    <React.Fragment>
      <b>
        {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.CULTURAL_COMPETENCE')}
      </b>
      <Layout.Stack alignItems="left" space={2}>
        {culturalCompetencies?.length > 0 &&
          isEnableL2CulturalCompetencies &&
          culturalCompetencies?.map(
            (competence) =>
              `${capitalizeEachWord(
                competence?.category
              )}: ${capitalizeEachWord(competence?.description)}`
          )}
        {culturalCompetencies?.length > 0 &&
          !isEnableL2CulturalCompetencies &&
          culturalCompetencies?.map(
            (competence) => `${capitalizeEachWord(competence)}`
          )}
        {(culturalCompetencies == null || culturalCompetencies.length <= 0) && (
          <Text
            color="#4B4D4F"
            fontWeight="$medium"
            size={mobileScreen ? '$md' : '18px'}
          >
            {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE')}
          </Text>
        )}
      </Layout.Stack>
    </React.Fragment>
  );
};
