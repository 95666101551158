import { DateTime } from 'api/src/graphql/DateTime';
import { differenceInCalendarDays, max, parseISO } from 'date-fns';

import { Constants } from '../common/Constants';

export const getDocAsapTargetUrl = (
  docAsapBaseUrl: string,
  docAsapPartnerCode: string,
  docAsapCredentialCode: string,
  docAsapChannelId: string,
  providerNpi: string,
  practiceId: string,
  isEAPEligible: boolean = false
) =>
  `${docAsapBaseUrl}/wl/key_npi/${providerNpi}/key_prac_id/${practiceId}/key_level/3/key_type/LINK/key_partner_code/${docAsapPartnerCode}?credentialCode=${docAsapCredentialCode}&channelId=${docAsapChannelId}&eapEligibility=${isEAPEligible}&returnTo=${docAsapBaseUrl}/wl/key_npi/${providerNpi}/key_prac_id/${practiceId}/key_level/3/key_type/LINK/key_partner_code/${docAsapPartnerCode}`;

const getDaysUntilFurthestDate = (availableDates: string[]) => {
  const currentDate = DateTime.getCurrentDateTime();
  const dateObject = availableDates
    .filter((date) => date)
    .map((date) => parseISO(date));

  //calculate dates that are within 0th(i.e first) to 30 (0-29) days
  const futureDates = dateObject.filter((date) => {
    const differenceDays = differenceInCalendarDays(date, currentDate);
    return differenceDays >= 0 && differenceDays < 30;
  });

  if (futureDates.length === 0) {
    return 0;
  }

  //find furthest date from the array
  const furthestDate = max(futureDates);

  const differenceDays = Math.ceil(
    (furthestDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
  );

  return differenceDays === 0 ? 1 : differenceDays + 1;
};

export const getAvailabilityText = (availableDates: string[]) => {
  const maxDifferenceFromToday = getDaysUntilFurthestDate(availableDates);
  let availabilityText: string = '';
  const {
    AVAILABLE_NEXT_TWO_DAYS,
    AVAILABLE_NEXT_SEVEN_DAYS,
    AVAILABLE_NEXT_FOURTEEN_DAYS,
    AVAILABLE_NEXT_THIRTY_DAYS,
  } = Constants.PROVIDER_DETAILS.LOCATIONS_TAB.AVAILABILITY_TEXTS;

  if (maxDifferenceFromToday <= 0 || maxDifferenceFromToday > 30) {
    return availabilityText;
  } else if (maxDifferenceFromToday >= 0 && maxDifferenceFromToday < 3) {
    availabilityText = AVAILABLE_NEXT_TWO_DAYS;
  } else if (maxDifferenceFromToday < 8) {
    availabilityText = AVAILABLE_NEXT_SEVEN_DAYS;
  } else if (maxDifferenceFromToday < 15) {
    availabilityText = AVAILABLE_NEXT_FOURTEEN_DAYS;
  } else {
    availabilityText = AVAILABLE_NEXT_THIRTY_DAYS;
  }
  return availabilityText;
};
