import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import { getStatusText } from '../../../../../../ShareDrawer/utils';
import {
  StyledOverflowTextAttributeDiv,
  popoverStyle,
} from '../../../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../../../utility/compareDrawerConstants';

type Props = {
  hasWeekendAppointment: boolean;
  mobile: boolean;
};

export const OffersWeekendAppointments = ({
  hasWeekendAppointment,
  mobile,
}: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const offerWeekendAppointmentsText = t(
    getStatusText(
      hasWeekendAppointment,
      ATTRIBUTE_STATUS_TEXT.OFFERS_WEEKEND_APPOINTMENTS.TRUE,
      ATTRIBUTE_STATUS_TEXT.OFFERS_WEEKEND_APPOINTMENTS.FALSE,
      NULL_ATTRIBUTE_STATE.OFFERS_WEEKEND_APPOINTMENTS
    )
  );

  const isAttributeEmpty =
    offerWeekendAppointmentsText ===
    NULL_ATTRIBUTE_STATE.OFFERS_WEEKEND_APPOINTMENTS;

  const OffersWeekendAppointments = (
    <StyledOverflowTextAttributeDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      ref={containerRef}
    >
      {hasWeekendAppointment ? (
        <Layout.Group space={4}>
          <IconMaterial color="$primary1" icon="today" size={20} />
          {offerWeekendAppointmentsText}
        </Layout.Group>
      ) : (
        offerWeekendAppointmentsText
      )}
    </StyledOverflowTextAttributeDiv>
  );

  if (mobile) {
    return (
      <React.Fragment>
        {isOverflow ? (
          <Popover
            content={offerWeekendAppointmentsText}
            css={popoverStyle}
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            {OffersWeekendAppointments}
          </Popover>
        ) : (
          OffersWeekendAppointments
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip
          content={offerWeekendAppointmentsText}
          position="top"
          positionOffset={8}
        >
          {OffersWeekendAppointments}
        </Tooltip>
      ) : (
        OffersWeekendAppointments
      )}
    </React.Fragment>
  );
};
