import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { Alert } from '@abyss/web/ui/Alert';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import find from 'lodash/find';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { useLagoon } from '../../hooks/useLagoon';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { DetailsStore } from '../../store/useDetailsStore/detailsStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getMemberHealthCoverageType } from '../../utils/providerSearch.utils';
import { getMemberLocation } from '../../utils/psxHeader.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { PSXHeaderCardWrapper } from '../CardWrapper';
import { ChipsCategory } from '../ChipsCategory';
import { Constants, CoverageTypesCodes, LOB } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { AlertStyling } from '../DetailsPageHeader/DetailsPageHeader.styled';
import { TabsCategory } from '../TabsCategory';
import { getFeatureFlag } from '../Utils';
import { getTypeAheadCategory } from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { getFormattedDate } from '../Utils/datesUtils/formatDate';
import { findLoggedInMember } from '../Utils/memberUtils/memberUtils';
import { Breadcrumb } from './Breadcrumb';
import { DemoHeader } from './DemoHeader/index';
import { MemberSelectionHeader } from './MemberSelectionHeader';
import { MemberInfo } from './ObapiDemo/memberProfile';
import { SearchBar } from './SearchBar';
import {
  getFilteredChips,
  handleSearchSelected,
  validateChipsToTabEnabled,
} from './SearchBar/utils';

const ChoosePCPHeader = lazy(async () => ({
  default: (await import('./ChoosePCPHeader/ChoosePCPHeader')).ChoosePCPHeader,
}));

type Props = {
  breadcrumbs?: any[];
  customMessage?: string;
  dataTestId?: string;
  isMixedResultsPage?: boolean;
  categoryCount?: { chipEnabled: boolean; chipCategory: string }[];
  onMemberIdChange?: (a: number) => void;
  onMemberDataChange?: (a: MemberInfo[]) => void;
  showChips?: boolean;
  showMemberSelection?: boolean;
  showChoosePCPHeader?: boolean;
  showSearchInputBackButton?: boolean;
};

export const PSXHeader = ({
  breadcrumbs = [],
  customMessage,
  dataTestId = '',
  isMixedResultsPage = false,
  categoryCount = [],
  onMemberIdChange = () => {},
  onMemberDataChange = () => {},
  showChips = true,
  showMemberSelection = true,
  showChoosePCPHeader = false,
  showSearchInputBackButton = false,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);

  const { MEMBER_LOCATION, ACTIVE_MEMBER_INDEX, LOGGED_IN_MEMBER_INDEX } =
    Constants.STORAGE_KEYS.SESSION;
  const { ZIPCODE } = Constants.STORAGE_KEYS.LOCAL;

  const { navigate } = useRouter();

  const [searchType, setSearchType] = useState('');
  const [providerType, setProviderType] = useState('');
  const [lob, setLob] = useState<LOB>(LOB.ENI);
  const obapimembers = useStore(StoreKeys.OBAPI_MEMBERS);
  const big5 = useStore(StoreKeys.PLAN_SELECTOR);
  const [memberProfiles, setMemberProfiles] =
    useState<MemberInfo[]>(obapimembers);
  const [memberLocation, setMemberLocation] = useState<string>(
    storage.session.get(MEMBER_LOCATION) ?? ''
  );
  const [activeMemberIndex, setActiveMemberIndex] = useState(
    Number(storage.session.get(ACTIVE_MEMBER_INDEX)) ?? 0
  );
  const [activeUser, setActiveUser] = useState<MemberInfo>(
    memberProfiles[activeMemberIndex]
  );
  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const lagoonData = useLagoon('ui-messaging')();

  const memberNotification = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.PRE_EFFECTIVE_NOTIFICATION,
  });

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setCoverageType, setChipValue } = chipStore;

  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const { setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const currentZipcode = storage.local.get(ZIPCODE);

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const chipsToTabsEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.CARE_CATEGORY_CHIPS_TO_TABS_ENABLED
  );
  const visionChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VISION_CHIP_ENABLED
  );
  const dentalChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DENTAL_CHIP_ENABLED
  );

  const currentMember = getCurrentMember();
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  const isPreEffective = useCurrentMemberIsPreEffective();

  const filteredChips = getFilteredChips(
    currentMember,
    isMixedResultsPage,
    categoryCount,
    visionChipEnabled,
    dentalChipEnabled
  );

  const chipsEnabled = validateChipsToTabEnabled(
    chipsToTabsEnabled,
    isMixedResultsPage
  );

  const form = useForm({
    defaultValues: {
      'care-category': 'all',
      'member-select': activeMemberIndex.toString(),
      search: '',
      includeSpecialityRollupCodes: '',
      searchType: '',
      firstName: 'FNUUYDQFZCCW',
      lastName: 'LNLNUBNRSM',
      dob: '02-02-1979',
      memberId: '00990062383',
      groupNumber: '0168504',
      portalSource: '',
      userName: '',
      id: '',
      lob: LOB.ENI,
    },
  });

  useEffect(() => {
    if (!currentZipcode) {
      form?.setValue('userZip', '07104');
      storage.local.set(ZIPCODE, '07104');
    }
  }, []);

  useEffect(() => {
    const loggedInMemberIndex = findLoggedInMember(memberProfiles, big5.bigN);

    if (!storage.session.get(ACTIVE_MEMBER_INDEX)) {
      storage.session.set(ACTIVE_MEMBER_INDEX, loggedInMemberIndex.toString());
    }
    if (!storage.session.get(LOGGED_IN_MEMBER_INDEX)) {
      storage.session.set(LOGGED_IN_MEMBER_INDEX, loggedInMemberIndex);
    }
    if (memberProfiles) {
      const memberIndex = Number(storage.session.get(ACTIVE_MEMBER_INDEX));
      form.setValue('member-select', memberIndex.toString());
      const location = getMemberLocation(memberProfiles[activeMemberIndex]);
      storage.session.set(MEMBER_LOCATION, location);
      setMemberLocation(location);
      setActiveMemberIndex(memberIndex);
      setActiveUser(memberProfiles[memberIndex]);
    }
  }, [memberProfiles, activeMemberIndex]);

  const onSubmit = (data) => {
    const searchTerm = form.getValues('searchTerm');
    const analyticsLinkName = 'search button';
    const typeaheadLinkName = 'typeahead search';
    const specialityPreferenceTable = useLagoon(
      Constants.LAGOON_TABLE.SPECIALITY_PREFERENCE
    )().map((item) => ({
      rollupCode: item.rollupCode,
      specialityCode: item.specialityCode,
    }));
    if (searchTerm?.length > 1) {
      handleSearchSelected(
        specialityPreferenceTable,
        {
          ...data,
          searchTerm,
          linkName: analyticsLinkName,
          typeaheadLinkName,
          searchMethod: 'typed',
        },
        {
          chipStore,
          setTypeaheadSearchStore,
          setAnalyticsState,
          setPCPSearchState,
          setChipValue,
          setCoverageType,
          setDetailsStore,
        },
        navigate
      );
    }

    let analyticsLocationName = 'search-input-option-list';
    let analyticsSearchBlock;
    if (data?.searchType) {
      analyticsLocationName += `:${getTypeAheadCategory(data?.resultType)}`;
      analyticsSearchBlock = {
        linkPosition: '1',
      };
    }
    adobeLinkTrackEvent({
      name: analyticsLinkName,
      location: analyticsLocationName,
      type: 'internal',
      destinationUrl: '',
      ...(analyticsSearchBlock && { searchBlock: analyticsSearchBlock }),
    });
  };

  const displayMobileBreadcrumbs = () => {
    if (mobileScreen && !showSearchInputBackButton) {
      return <Breadcrumb breadcrumbs={breadcrumbs} />;
    }
    return null;
  };
  return (
    <React.Fragment>
      {isPreEffective && (
        <Alert
          children={memberNotification?.message}
          css={AlertStyling}
          data-testid="member-notification-alert"
          title={`${memberNotification?.title} ${getFormattedDate(
            medicalCoverage?.coverageEffDateRange?.startDate
          )}`}
          variant="info"
        />
      )}
      <PSXHeaderCardWrapper
        backgroundColor={'$bgHeaderColor'}
        showSearchInputBackButton
      >
        {!mobileScreen && !showChoosePCPHeader ? (
          <Breadcrumb breadcrumbs={breadcrumbs} />
        ) : null}
        <FormProvider
          data-auto-testid={dataTestId}
          data-testid={dataTestId}
          onSubmit={onSubmit}
          state={form}
        >
          <Layout.Stack grow space={0}>
            <Layout.Group alignItems="top">
              {displayMobileBreadcrumbs()}
              {showMemberSelection ? (
                <MemberSelectionHeader
                  activeUser={activeUser}
                  form={form}
                  memberProfiles={memberProfiles}
                  setActiveMemberIndex={setActiveMemberIndex}
                />
              ) : null}
              {showChoosePCPHeader ? (
                <Suspense fallback={<div>Loading...</div>}>
                  <Layout.Stack grow space={0}>
                    {!mobileScreen ? (
                      <Breadcrumb breadcrumbs={breadcrumbs} />
                    ) : null}
                    <ChoosePCPHeader />
                  </Layout.Stack>
                </Suspense>
              ) : null}
            </Layout.Group>

            <DemoHeader
              form={form}
              lob={lob}
              onMemberDataChange={onMemberDataChange}
              onMemberIdChange={onMemberIdChange}
              providerType={providerType}
              searchType={searchType}
              setLob={setLob}
              setMemberProfiles={setMemberProfiles}
              setProviderType={setProviderType}
              setSearchType={setSearchType}
            />
            <SearchBar
              breadcrumbs={breadcrumbs}
              chipsToTabsEnabled={chipsToTabsEnabled}
              choosePCPHeader={showChoosePCPHeader}
              isMixedResultsPage={isMixedResultsPage}
              memberLocation={memberLocation}
              showSearchInputBackButton={showSearchInputBackButton}
            />
            {showChips && chipsEnabled && (
              <Layout.Stack grow space={0}>
                <ChipsCategory
                  chips={filteredChips}
                  customMessage={customMessage}
                  data-auto-testid="search-home-chips-category"
                  data-testid="search-home-chips-category"
                  isMixedResultsPage={isMixedResultsPage}
                />
              </Layout.Stack>
            )}
          </Layout.Stack>
        </FormProvider>
      </PSXHeaderCardWrapper>
      {showChips && chipsToTabsEnabled && !isMixedResultsPage && (
        <Layout.Stack css={{ paddingBottom: '8px' }} grow space={0}>
          <TabsCategory
            chips={filteredChips}
            customMessage={customMessage}
            data-auto-testid="search-home-tabs-category"
            data-testid="search-home-tabs-category"
            isMixedResultsPage={isMixedResultsPage}
          />
        </Layout.Stack>
      )}
    </React.Fragment>
  );
};
